import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"

import EditCustomAsset from "./editCustomAsset"
import AxiosInstance from "../../../../services/axiosInstance"
import { ASSET_TYPE_NOT_APPLICABLE, LOCATION_TYPE_FIXED } from "./assetTypeHelper"
import Tags from "./tags"
import { Link } from "gatsby"

const CustomAsset = ({ icons }) => {
  const [assetTypeForm, setAssetTypeForm] = useState(false),
    [assetTypes, setAssetTypes] = useState([]),
    [assetType, setAssetType] = useState(""),
    [reloadData, setReloadData] = useState(false),
    [name, setName] = useState(""),
    [userAssetType, setUserAssetType] = useState(ASSET_TYPE_NOT_APPLICABLE),
    [locationType, setLocationType] = useState(LOCATION_TYPE_FIXED),
    [tempTags, setTempTags] = useState([]),
    [industryIcon, setIndustryIcon] = useState("0-circle"),
    [iconColor, setIconColor] = useState("black"),
    [iconBgColor, setIconBgColor] = useState("grey")

  const handleAdd = () => {
    setAssetTypeForm(true)
    setAssetType("")
    setName("")
    setUserAssetType(ASSET_TYPE_NOT_APPLICABLE)
    setTempTags([])
    setLocationType(LOCATION_TYPE_FIXED)
    setIndustryIcon("0-circle")
    setIconColor("black")
    setIconBgColor("grey")
  }
  const handleEditForm = id => {
    setAssetTypeForm(true)
    let filterData = assetTypes.filter(data => {
      return data.id === id
    })
    setAssetType(filterData[0])
    setName(filterData[0].name)
    setUserAssetType(filterData[0].assetType)
    setTempTags(filterData[0].tags)
    setLocationType(filterData[0].locationType)
    setIndustryIcon(filterData[0].icon)
    setIconColor(filterData[0].iconColor)
    setIconBgColor(filterData[0].iconBackgroundColor)
  }

  const assetTypesData = () => {
    AxiosInstance.get("/settings/asset-type")
      .then(response => {
        setAssetTypes(response.data.items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDelete = Id => {
    AxiosInstance.delete(`/settings/asset-type/${Id}`)
      .then(response => {
        if (response.status === 200) {
          assetTypesData()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlePrimary = Id => {
    AxiosInstance.post(`/settings/asset-type/make-primary/${Id}`)
      .then(response => {
        if (response.status === 200) {
          assetTypesData()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    assetTypesData()
  }, [])

  useEffect(() => {
    if (reloadData === true) {
      assetTypesData()
      setReloadData(false)
    }
  })

  return (
    <Accordion.Item eventKey="4">
      <Accordion.Header data-bs-target="#customAssetTypesSetting" aria-controls="customAssetTypesSetting">
        <i className="bi bi-asterisk me-2" />
        Custom Asset Types
      </Accordion.Header>
      <Accordion.Body
        id="customAssetTypesSetting"
        aria-labelledby="customAssetTypesSetting"
        data-bs-parent="#generalSettings"
      >
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            {assetTypeForm === true && (
              <EditCustomAsset
                setAssetTypeForm={setAssetTypeForm}
                assetType={assetType}
                name={name}
                setName={setName}
                userAssetType={userAssetType}
                setUserAssetType={setUserAssetType}
                locationType={locationType}
                setLocationType={setLocationType}
                tempTags={tempTags}
                setTempTags={setTempTags}
                setReloadData={setReloadData}
                industryIcon={industryIcon}
                setIndustryIcon={setIndustryIcon}
                iconColor={iconColor}
                setIconColor={setIconColor}
                iconBgColor={iconBgColor}
                setIconBgColor={setIconBgColor}
              />
            )}
            <div className="row mb-2 align-items-center">
              <div className="col-md-6">
                <h4 className="mb-0">
                  <i className="bi bi-asterisk" /> Custom Asset Types
                </h4>
              </div>
              <div className="col-md-6 text-end">
                <button className="btn btn-primary btnAddNewAssetType" onClick={handleAdd}>
                  <i className="bi bi-plus-lg" /> Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped dataTable no-footer" id="tblAssetTypes" width="100%">
                <thead>
                  <tr>
                    <th width="5%">&nbsp;</th>
                    <th width="40%">Name</th>
                    <th width="40%">Default Tags</th>
                    <th width="20%" />
                  </tr>
                </thead>
                <tbody>
                  {assetTypes.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No Custom Status Added Yet.
                        <a style={{ color: "#125fad", cursor: "pointer" }} onClick={handleAdd}>
                          {" "}
                          Click here to add one.
                        </a>
                      </td>
                    </tr>
                  )}
                  {assetTypes.map((type, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center tableColChkDel">
                          {/* <a href="#" target="_blank">
                              <i className={`bi bi-${type.icon}`} /> {type.name}
                            </a> */}
                          <div className="tableColChkDelBox">
                            <input
                              type="checkbox"
                              name="fileid[]"
                              id={`PJSO_ID${type.id}`}
                              className="css-checkbox"
                            />
                            <label htmlFor={`PJSO_ID${type.id}`} className="css-checkbox-label"></label>
                            <span
                              className={`tableColChkSpan color-${type.iconColor}`}
                              style={{ backgroundColor: type.iconBackgroundColor }}
                            >
                              <i className={`bi bi-${type.icon}`}></i>
                            </span>
                          </div>
                        </td>

                        <td>
                          <Link to={``} target="_blank">
                            {type.name}
                          </Link>{" "}
                        </td>

                        <td>
                          <Tags
                            type={type}
                            tags={type.tags}
                            assetType={assetType}
                            setAssetType={setAssetType}
                            assetTypes={assetTypes}
                          />
                        </td>
                        <td className="text-end tableColAction d-none d-md-table-cell">
                          {!type.isPrimary ? (
                            <a
                              className="btn btn-secondary"
                              onClick={() => {
                                handlePrimary(type.id)
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Make Primary</Tooltip>}>
                                <i className="bi bi-check2" />
                              </OverlayTrigger>
                            </a>
                          ) : (
                            <a
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                return false
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Primary</Tooltip>}>
                                <i className="bi bi-check2" />
                              </OverlayTrigger>
                            </a>
                          )}{" "}
                          <a
                            className="btn btn-edit"
                            onClick={() => {
                              handleEditForm(type.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                              <i className="bi bi-pencil-fill" />
                            </OverlayTrigger>
                          </a>{" "}
                          <a
                            className="btn btn-delete"
                            onClick={() => {
                              handleDelete(type.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                              <i className="bi bi-trash-fill" />
                            </OverlayTrigger>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default CustomAsset
