import React, { useState } from "react"
import { OverlayTrigger, Tooltip, Accordion } from "react-bootstrap"
import { Link } from "gatsby"
import ZapierPopup from "./zapierPopup"

const ApiSettings = () => {
  const [addApi, setAddApi] = useState(false)
  const [apiBridgeType, setApiBridgeType] = useState("Zapier")
  const [modalShow, setModalShow] = useState(false)

  const handleApiAdd = () => {
    setAddApi(true)
  }
  return (
    <Accordion.Item eventKey="7">
      <Accordion.Header>
        <i className="bi bi-plugin me-2" /> API Settings: Connect Other Software
        <span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                This section can help you "glue" software so it works together. It creates an "API Data
                Bridge" of inbound + outbound pathways.
              </Tooltip>
            }
          >
            <i className="bi bi-question-circle-fill ms-1"></i>
          </OverlayTrigger>
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            {addApi === true && (
              <div className="row">
                <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewAPIBridgeContainer">
                  <div className="row">
                    <div className="col-lg-12 colHeading">
                      <strong>
                        <i className="bi bi-plus-lg"></i>
                        API "Bridge" Connection
                      </strong>
                    </div>
                    <div className="col-lg-12 dh-block-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row mb-3 align-items-center">
                                <div className="col-sm-4">API / Software to Connect</div>
                                <div className="col-sm-8">
                                  <select
                                    name="APIBridgeType"
                                    id="APIBridgeType"
                                    className="form-select"
                                    value={apiBridgeType}
                                    onChange={e => {
                                      setApiBridgeType(e.target.value)
                                    }}
                                  >
                                    <optgroup label="=== Connector Marketplaces ===">
                                      <option value="Zapier">Zapier</option>
                                      <option value="Makecom">Make.com</option>
                                    </optgroup>
                                    <optgroup label="=== AI Engines ===">
                                      <option value="ChatGPT">ChatGPT / Open AI</option>
                                      <option value="Claude">Claude by Anthropic</option>
                                      <option value="Gemini">Gemini by Google</option>
                                      <option value="Llama">Llama by Facebook / Meta</option>
                                      <option value="Grok">Grok by X / Twitter</option>
                                    </optgroup>
                                    <optgroup label="=== Image Creation ===">
                                      <option value="Midjourney">Midjourney</option>
                                      <option value="DallE">Dall-E / Open AI</option>
                                    </optgroup>
                                    <optgroup label="=== Audio Creation ===">
                                      <option value="ElevenLabs">ElevenLabs</option>
                                    </optgroup>
                                    <optgroup label="=== Video Creation ===">
                                      <option value="HeyGen">HeyGen</option>
                                    </optgroup>
                                  </select>
                                </div>
                              </div>
                              {apiBridgeType === "Zapier" ? (
                                <div className="mb-3 row bridgeTypeConnection bridgeTypeZapier">
                                  <label className="col-sm-4 col-form-label"></label>
                                  <div className="col-sm-8">
                                    <button
                                      className="btn btnZapier"
                                      type="button"
                                      data-bs-target="#ZapierAuthModal"
                                      onClick={() => setModalShow(true)}
                                    >
                                      <strong>Z</strong>| Connect with Zapier
                                    </button>
                                    <ZapierPopup modalShow={modalShow} setModalShow={setModalShow} />
                                  </div>
                                </div>
                              ) : (
                                <div className="mb-3 row bridgeTypeConnection bridgeType_Makecom">
                                  <label className="col-sm-4 col-form-label" />
                                  <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="secret_key"
                                      placeholder="Enter Secret Key"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-sm-12 text-end">
                                  <button
                                    className="btn btn-default"
                                    type="button"
                                    name="btnCancel"
                                    id="btnCancelAddNewAPIBridge"
                                    onClick={() => setAddApi(false)}
                                  >
                                    <i className="bi bi-x-lg"></i>
                                    Cancel
                                  </button>{" "}
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    name="btnSave"
                                    id="btnSaveAPIBridge"
                                  >
                                    <i className="bi bi-check2"></i>
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-2 align-items-center">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-end">
                <button className="btn btn-primary btnAddNewAPIBridge" onClick={handleApiAdd}>
                  <i className="bi bi-plus-lg"></i>
                  Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped dataTable no-footer" id="tblAPIBridge" width="100%">
                <thead>
                  <tr>
                    <th width="80%">Event Types</th>
                    <th width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-center">
                      No API Bridge Connections Yet. Want to{" "}
                      <a style={{ color: "#125fad", cursor: "pointer" }} onClick={() => setAddApi(true)}>
                        click here & add one
                      </a>{" "}
                      now?
                    </td>
                  </tr>
                  {/* <tr>
                            <td>Bridge name 1</td>
                            <td className='text-end tableColAction d-none d-md-table-cell'>
                            <Link to='' className='btn btn-edit'>
                                <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                    Edit
                                    </Tooltip>
                                }
                                >
                                <i className="bi bi-pencil-fill"></i>
                                </OverlayTrigger>
                            </Link>{" "}
                            <Link to='' className='btn btn-delete'>
                                <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                    Delete
                                    </Tooltip>
                                }
                                >
                                <i className="bi bi-trash-fill"></i>
                                </OverlayTrigger>
                            </Link>
                            </td>
                        </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default ApiSettings
