import React, { useState } from "react"
import { Form } from "react-bootstrap"

import { ASSET_TYPES, LOCATION_TYPES } from "./assetTypeHelper"
import AxiosInstance from "../../../../services/axiosInstance"
import AssetTagField from "./assetTagField"
import TransactionTypeIconModel from "../Project/transactionTypeIconModel"

const EditCustomAsset = ({
  assetType,
  locationType,
  name,
  setAssetTypeForm,
  setLocationType,
  setName,
  setUserAssetType,
  userAssetType,
  tempTags,
  setTempTags,
  setReloadData,
  industryIcon,
  setIndustryIcon,
  iconColor,
  setIconColor,
  iconBgColor,
  setIconBgColor,
}) => {
  const [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [removeTagIndex, setRemoveTagIndex] = useState(null),
    [showModal, setShowModal] = useState(false)

  const handleEdit = () => {
    if (!name) {
      alert("Please add label.")
      return false
    }

    let url = `/settings/asset-type/add`
    if (assetType) {
      url = `/settings/asset-type/edit/${assetType.id}`
    }
    AxiosInstance.post(url, {
      name,
      icon: industryIcon,
      iconColor: iconColor,
      iconBackgroundColor: iconBgColor,
      assetType: userAssetType,
      locationType,
      tags: tempTags,
    })
      .then(response => {
        setReloadData(true)
        setAssetTypeForm(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("")
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-75"
          placeholder="Search"
          onChange={e => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child => !value || child.props.value.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    )
  })

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewAssetTypeContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-asterisk" /> {`${assetType ? "Edit" : "Add"}`} Asset Type
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Asset Type Name/Label</div>

                      <div className="col-sm-8">
                        <div className="input-group">
                          <button
                            type="button"
                            className={`btn btnPageTitle_Editor_Icon color-${iconColor}`}
                            style={{ backgroundColor: iconBgColor }}
                            onClick={() => {
                              setShowModal(true)
                            }}
                          >
                            <i className={`bi bi-${industryIcon}`} />
                          </button>

                          <input
                            type="text"
                            className="form-control"
                            name="asset_name"
                            id="asset_name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <TransactionTypeIconModel
                        showModal={showModal}
                        setShowModal={setShowModal}
                        iconColor={iconColor}
                        setIconColor={setIconColor}
                        iconBgColor={iconBgColor}
                        setIconBgColor={setIconBgColor}
                        industryIcon={industryIcon}
                        setIndustryIcon={setIndustryIcon}
                      />
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Use is</div>
                      <div className="col-sm-8">
                        <select
                          className="form-select"
                          name="asset_type_use"
                          value={userAssetType}
                          onChange={e => setUserAssetType(e.target.value)}
                        >
                          {ASSET_TYPES.map((assetTypeOption, index) => {
                            return <option key={index}>{assetTypeOption}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Location is</div>
                      <div className="col-sm-8">
                        <select
                          className="form-select"
                          name="asset_location_type"
                          id="asset_location_type"
                          value={locationType}
                          onChange={e => setLocationType(e.target.value)}
                        >
                          {LOCATION_TYPES.map((locationTypeOption, index) => {
                            return <option key={index}>{locationTypeOption}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <AssetTagField
                      addTag={addTag}
                      setAddTag={setAddTag}
                      newTag={newTag}
                      setNewTag={setNewTag}
                      tempTags={tempTags}
                      setTempTags={setTempTags}
                      removeTagIndex={removeTagIndex}
                      setRemoveTagIndex={setRemoveTagIndex}
                      filterRoleTableId={assetType}
                    />
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelAddNewCustomField"
                          className="btn btn-default"
                          onClick={() => setAssetTypeForm(false)}
                        >
                          <i className="bi bi-x-lg" />
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveCustomField"
                          className="btn btn-primary"
                          onClick={handleEdit}
                        >
                          <i className="bi bi-check2" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCustomAsset
